import { Link } from "gatsby"
import React from "react"
import Logo from "./logo"
import { Menu } from "./menu/menu"
import menuStyles from "./menu/menu.module.scss"

export default ({ children }) => {
  return (
    <div>
      <section className={menuStyles.logoWrapper}>
        <Link to="/">
          <Logo />
        </Link>
      </section>

      <Menu />
      <script defer src="https://eu.umami.is/script.js" data-website-id="67e0763a-2651-4476-a91f-5473a1de2e44"></script>
      <script defer src="/posthog.js"></script>

      {children}
    </div>
  )
}

import React from "react"
import menuStyles from "./menu.module.scss"
import { Link } from "gatsby"
import Logo from "../logo"
import $ from "jquery"

const classNames = require("classnames")

export class Menu extends React.Component {
  constructor(props) {
    super(props)
    this.toggleMenu = this.toggleMenu.bind(this)
    this.state = { expanded: false }
  }

  componentDidMount() {
    $(document).ready(function() {
      $(window).on("scroll", () => {})

      var navbar = $("#navbar")

      $(window).scroll(function() {
        var windowpos = $(window).scrollTop()

        if (windowpos >= 80) {
          navbar.addClass(classNames(menuStyles.scrolledbg))
        } else {
          navbar.removeClass(classNames(menuStyles.scrolledbg))
        }
      })
    })
  }

  toggleMenu() {
    this.setState({ expanded: !this.state.expanded })
  }

  render() {
    let menuClasses = classNames(
      menuStyles.menu,
      this.state.expanded ? menuStyles.menuExpanded : ""
    )

    const burgerClasses = classNames(
      { [menuStyles.burger]: true },
      this.state.expanded ? menuStyles.expanded : ""
    )

    const topClasses = classNames(
      { [menuStyles.top]: true },
      this.state.expanded ? menuStyles.expanded : ""
    )
    const middleClasses = classNames(
      { [menuStyles.middle]: true },
      this.state.expanded ? menuStyles.expanded : ""
    )
    const bottomClasses = classNames(
      { [menuStyles.bottom]: true },
      this.state.expanded ? menuStyles.expanded : ""
    )
    const scrollbgClasses = classNames(
      { [menuStyles.scrollbg]: true },
      this.state.expanded ? menuStyles.expanded : ""
    )

    return (
      <div>
        <div className={burgerClasses} onClick={this.toggleMenu}>
          <span className={topClasses}>&nbsp;</span>
          <span className={middleClasses}>&nbsp;</span>
          <span className={bottomClasses}>&nbsp;</span>
        </div>
        <span id="navbar" className={scrollbgClasses}>
          &nbsp;
        </span>
        <div className={menuClasses}>
          <Link to="/">
            <Logo />
          </Link>
          <nav>
            <ul>
              <li>
                <Link to="/contact">
                  <span>Contact</span>
                </Link>
              </li>
              <li>
                <Link to="/imprint">
                  <span>Imprint</span>
                </Link>
              </li>
              <li>
                <Link to="/privacy-policy">
                  <span>Privacy Policy</span>
                </Link>
              </li>
              <li>
                <Link to="/terms-and-conditions">
                  <span>Terms & Conditions</span>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    )
  }
}
